<template>
    <PrimaryButton
        @click="() => router.visit(route('register.with-price', {price: products[1].value}), { method: 'get' })"
        class="scale-125 lg:scale-150 origin-center">
        <MdiIcon :icon="mdiBullseyeArrow" class="w-6 h-6 mr-1"/>
        Start Your Free Trial
    </PrimaryButton>
</template>
<script setup lang="ts">
import {router, usePage} from "@inertiajs/vue3";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import {mdiBullseyeArrow} from "@mdi/js";
import MdiIcon from "@/Components/MdiIcon.vue";

const products = usePage().props.products

</script>
